$.lui("comp", ".comp_rooms", function(selector){

    let section = selector.find(".part_item_rooms");
    let sections = [];
    let menu = selector.find("[data-scrollspy-nav]");

    function fn_sections_offset() {
        sections = [];
        section.each(function(){
            let elm = $(this);
            sections.push(elm.offset().top);
        });
    }

    win.on("resize", fn_sections_offset);
    fn_sections_offset();

    win.on("scroll", function() {
        if (win.width() > 960) {
            let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

            sections.forEach(function(i, index){
                if (i <= scrollPosition + $("#layout_header").outerHeight() + 60) {
                    menu.find("a").removeClass("state--active");
                    menu.children("li").eq(index).find("a").addClass("state--active");
                }
            });
        }
    });

});