$.lui("lib_wsw", ".part_ui_wsw", function(selector){
    selector.find("table").each(function() {
        $(this).wrap("<div class='elm_table'></div>");
    });
    selector.find(".row--images img").each(function() {
        $(this).wrap(`<a href="${$(this).attr("src")}" class="part_ui_image lib--fancybox-item"></a>`);
    });
    selector.find('iframe').filter(function(){
        return this.src.match(/youtube\.com/i);
    }).wrap("<div class='elm_video'></div>");
});