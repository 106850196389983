function fn_lib_dialog() {
    const dialog = $("[data-lib-dialog]");

    function fn_lib_dialog_show(data, callback) {
        doc.find(".lib--dialog .part_dialog").remove();

        if (!doc.find(".lib--dialog").length) {
            body.addClass("body--no-scroll").append('<div class="lib--dialog"><div class="lib--dialog-background"></div></div>');
        }

        doc.find(".lib--dialog").append(data).fadeIn(300);
        doc.find(".lib--dialog .part_dialog").focus();

        if (callback) {
            callback();
        }
    }

    function fn_lib_dialog_hide(callback) {
        body.removeClass("overflow--no-scroll");
        dialog.removeClass("state--active");
        doc.find(".lib--dialog").fadeOut(300);

        setTimeout(function () {
            doc.find(".lib--dialog").remove();
            if (callback) {
                callback();
            }
        }, 500);
    }

    function fn_lib_dialog_init(callback) {
        doc.on('click', '[data-lib-dialog]', function (e) {
            e.preventDefault();
            let el = $(this);

            el.addClass("state--active");

            $.ajax({
                dataType: "json",
                data: {ajax: +new Date},
                url: el.data('lib-dialog')
            }).done(function(data) {
                fn_lib_dialog_show(data.dialog, callback);
            });
        });

        if (body.filter("[data-lib-dialog-open]").length) {
            $.ajax({
                dataType: "json",
                data: {ajax: +new Date},
                url: body.data('lib-dialog-open')
            }).done(function(data) {
                bodyLoaded(function(){
                    fn_lib_dialog_show(data.dialog, callback);
                });
            });
        }

        doc.on("click", ".lib--dialog-background, [data-lib-dialog-close]", function (e) {
            e.preventDefault();
            fn_lib_dialog_hide();
        });
    }

    return {
        open: fn_lib_dialog_show,
        close: fn_lib_dialog_hide,
        init: fn_lib_dialog_init
    };
}
const lui_dialog = fn_lib_dialog();