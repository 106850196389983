let $ = jQuery;
let doc = $(document),
    win = $(window),
    html = $("html"),
    body = $("body"),
    lang = html.attr('lang');

window.lui = {
    layout: {},
    comp: {},
    part: {},
    lib: {}
};

if ('serviceWorker' in navigator && location.protocol === "https:") {
    window.addEventListener('load', function() {
        if (!html.hasClass("no-sw")) {
            navigator.serviceWorker.register('/sw.js').catch(function(e) {
                console.error('Error during service worker registration:', e);
            });
        } else {
            navigator.serviceWorker.getRegistrations().then(function(registrations) {
                if (registrations.length > 0) {
                    for(let registration of registrations) {
                        registration.unregister()
                    }
                }
            });
        }
    });
}

(function() {
    cssLoaded(function(){
        body.removeClass("body--preload");
        setTimeout(function () {
            body.addClass("body--loaded");
        }, 300);
    });
})();

document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
});
