$.fn.lib_reCapcha = function (attr) {
    let elm = $(this);
    if(elm.length) {
        $.importScript(cdnjs.recapcha.replace("{apikey}",attr), function(){
            grecaptcha.ready(function() {
                grecaptcha.execute(attr, {action: 'form'}).then(function(token) {
                    elm.find(`[name="g-token"]`).val(token);
                });
            });
        })
    }
};